<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToPage('/bigscreen/menuPage')">巴林右旗{{ mapName }}牧业统计</div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <div class="left_box">
          <div class="title"><span>养殖类</span></div>
          <bing-tu :value="data2" />
        </div>

        <div class="left_box">
          <div class="title"><span @click="goToPage('/bigscreen/villageFangYiTongJi大坂镇')">防疫统计</span></div>
          <ban-bing-tu :value="data1" />
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="map_box">
          <!-- <da-ban-map /> -->
          <da-ban-map v-if="mapName == '大板镇'" />
          <su-bo-ri-ga v-if="mapName == '索博日嘎镇'" />
          <xing-fu-zhi-lu v-if="mapName == '幸福之路苏木'" />
          <ba-yan-hu-shuo-hamlet v-if="mapName == '巴彦琥硕镇'" />
          <cha-gan-mu-lun-hamlet v-if="mapName == '查干沐沦苏木'" />
          <mei-dian-hua v-if="mapName == '大板煤电化基地'" />

          <ba-yan-ta-la v-if="mapName == '巴彦塔拉苏木'" />
          <cha-gan-nuo-er v-if="mapName == '查干诺尔镇'" />
          <bao-ri-wu-su v-if="mapName == '宝日勿苏镇'" />
          <xi-la-mu-lun v-if="mapName == '西拉沐沦苏木'" />
        </div>

        <div class="context_box">
          全镇总土地面积 1925 平方公里，
          全镇大小畜存栏 22.6 万头只，其中大畜牛 3.58 万头。
          永久性棚圈建设 5.3 万平方米，青贮窖建设 8300 立方米。
        </div>

        <div class="bottom_2_box">
          <div class="bottom_left_box" @click="goToPersonStateDetail()">
            <div class="title"><span>人口</span></div>
            <div class="item_box">
              <div class="item" v-for="(item, index) in personList" :key="index">
                {{ item.name }}: {{ item.value }}人
              </div>
            </div>
          </div>
          <div class="bottom_right_box" @click="goToMashineStateDetail()">
            <div class="title"><span>机械化使用</span></div>
            <div class="item_box">
              <div class="item" v-for="(item, index) in mashineList" :key="index">
                {{ item.name }}: {{ item.value }}辆
              </div>
            </div>
          </div>  
        </div>
      </div> 

      <!-- 3 -->
      <div class="content_item_box">
        <div class="mu_hu_list">
          <!-- <div class="title"><span>牧户列表</span></div>

          <div class="list_box">
            <div class="list_item">
              <span>01</span> 
              <div class="name main_color" @click="goToShiFanDian('牧户1')">示范点牧户1</div> 
              <div class="value">牲畜数量： 24</div> 
            </div>
            <div class="list_item">
              <span>02</span> 
              <div class="name main_color" @click="goToShiFanDian('牧户2')">示范点牧户2</div> 
              <div class="value">牲畜数量： 54</div> 
            </div>
            <div class="list_item">
              <span>03</span> 
              <div class="name main_color" @click="goToShiFanDian('牧户3')">示范点牧户3</div> 
              <div class="value">牲畜数量： 87</div> 
            </div>
            <div class="list_item">
              <span>03</span> 
              <div class="name main_color" @click="goToShiFanDian('牧户4')">示范点牧户4</div> 
              <div class="value">牲畜数量： 45</div> 
            </div>

            <div class="list_item" v-for="(item, index) in muHuList" :key="index">
              <span v-if="index < 9">0{{ index+4 }}</span> 
              <span v-else>{{ index+1 }}</span> 

              <div class="name ellipsis" @click="goToMuHuDetail(item.id)">{{ item.household_name }}</div> 

              <div class="value">牲畜数量： {{ item.cattle_num }}</div> 
            </div>
          </div> -->

          <div class="title"><span>嘎查村列表</span></div>

          <div class="list_box">
            <div class="list_item mb_5 font_number" v-for="(item, index) in hamletList" :key="index" v-show="$route.params.val == '大板镇'">
              <div class="w_full text_center cursor" @click="goToHamletDetail(item.hamlet_name)">{{ item.hamlet_name }}</div> 

              <!-- <div class="value w_30_p"></div>  -->
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import BingTu from '../charts/bing-tu.vue'
  import BanBingTu from '../charts/ban-bing-tu.vue';
  
  import { getMuHuList, getGaChaCunTongJi } from '../../../../config/api';

  import DaBanMap from '../maps/daBanMap.vue'
  import SuBoRiGa from '../maps/suBoRiGa.vue'
  import XingFuZhiLu from '../maps/xingFuZhiLu.vue'
  import BaYanHuShuoHamlet from '../maps/ba-yan-hu-shuo-hamlet.vue'
  import ChaGanMuLunHamlet from '../maps/cha-gan-mu-lun-hamlet.vue'
  import MeiDianHua from '../maps/mei-dian-hua.vue'
  import BaYanTaLa from '../maps/ba-yan-ta-la.vue'
  import BaoRiWuSu from '../maps/bao-ri-wu-su.vue'
  import XiLaMuLun from '../maps/xi-la-mu-lun.vue'
  import ChaGanNuoEr from '../maps/cha-gan-nuo-er.vue'
  import kongXinBingTu from '../charts/kong-xin-bing-tu.vue'

  export default {
    name: 'showPage1',
    components: { 
      BaLinYouQiMap, 
      BingTu, 
      BanBingTu,
      DaBanMap, 
      SuBoRiGa, 
      XingFuZhiLu, 
      BaYanHuShuoHamlet,
      ChaGanMuLunHamlet,
      MeiDianHua,
      BaYanTaLa,
      BaoRiWuSu,
      XiLaMuLun,
      ChaGanNuoEr, 
      kongXinBingTu,
    },
    data() {
      return {
        activeIndex: 0,
        mapName: '巴林右旗大板镇',

        hamletList: [
          {hamlet_name: "翁根毛都嘎查"},
          {hamlet_name: "套白村"},
          {hamlet_name: "伊逊毛都嘎查"},
          {hamlet_name: "巴彦火烧嘎查"},
          {hamlet_name: "阿日班格日嘎查"},
          {hamlet_name: "准哈日毛都嘎查"},
          {hamlet_name: "那日斯台嘎查"},
          {hamlet_name: "南市街"},
          {hamlet_name: "乌兰图嘎嘎查"},
          {hamlet_name: "归勒斯台嘎查"},
          {hamlet_name: "浩饶沁嘎查"},
          {hamlet_name: "大冷村"},
          {hamlet_name: "古力古台嘎查"},
          {hamlet_name: "吉布吐嘎查"},
          {hamlet_name: "西哈嘎查"},
          {hamlet_name: "温根吐嘎查"},
          {hamlet_name: "苏艾力嘎查"},
          {hamlet_name: "昭胡都格嘎查"},
        ],

        data1: {},
        data2: {},
        personList: [
          { name: '14岁以下', value: 4133 },
          { name: '15 - 64岁', value: 4128 },
          { name: '65岁以上', value: 5352 }
        ],
        mashineList: [
          { name: ' 四轮车', value: 3500 },
          { name: '大型货车', value: 18 },
          { name: '大型拖拉机', value: 91 },
          { name: '播种机械', value: 2800 },
          { name: '打草机械', value: 580 },
          { name: '家用车辆', value: 450 },
        ],

        muHuList: [],
      }
    },
    mounted() {
      this.mapName = this.$route.params.val
      
      setTimeout(() => {
        this.initChart()
        this.getMuHuList()
        // this.getHamletList()
      }, 100)



      // this.$bus.$on('setData', val => {
      //   this.mapName = val.mapName
      //   this.data1 = val.zhong_zhi_zhong_lei
      //   this.data2 = val.yang_zhi_lei
      //   this.personList = val.person_data
      //   this.mashineList = val.mashine_data
      // })
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: '防疫统计',
          unit: '头',
          data: [
            {"name": "牛", "value": 18548, "unit": ''},
            {"name": "马", "value": 9832, "unit": ''},
            {"name": "绵羊", "value": 7821, "unit": ''},
            {"name": "山羊", "value": 9676, "unit": ''},
            {"name": "驴", "value": 243, "unit": ''},
            {"name": "骆驼", "value": 3451, "unit": ''}
          ]
        }

        this.data2 = {
          title: '养殖类',
          unit: '头',
          data: [
            {"name": "牛", "value": 23570, "unit": ''},
            {"name": "马", "value": 12432, "unit": ''},
            {"name": "绵羊", "value": 12521, "unit": ''},
            {"name": "山羊", "value": 13676, "unit": ''},
            {"name": "驴", "value": 2343, "unit": ''},
            {"name": "骆驼", "value": 3451, "unit": ''}
          ]
        }
      },

      // 获取牧户列表
      async getMuHuList() {
        let params = { village: this.$route.params.val }
        let res = await getMuHuList(params)
        
        this.muHuList = res.data.results.sort((a, b) => {
          return b.cattle_num - a.cattle_num
        })
      },

      // 获取 镇 下的 嘎查村 列表
      async getHamletList() {
        let params = { xz: this.$route.params.val }
        let res = await getGaChaCunTongJi(params)

        console.log('嘎查村 : ', res);
        
        // this.hamletList = res.data.results
      },

      // 跳转到牧户详情页
      goToMuHuDetail(id) {
        let muHuId = id

        this.$router.push({
          path: "/bigscreen/muHuPage" + muHuId
        })
      },

      // 跳转到嘎查详情页
      goToHamletDetail(val) {
        this.$router.push({
          path: "/bigscreen/hamletAnimalPage" + val
        })
      },

      // 跳转到示范点牧户大屏
      goToShiFanDian(val) {
        this.$router.push({
          path: "/demo-makido" + val
        })
      },

      // 跳转页面
      goToPage(val) {
        this.$router.push({
          path: val
        })
      },

      clickTab(val) {
        this.activeIndex = val
        if (val == 0) {
          this.list = this.list1
        } else if (val == 1) {
          this.list = this.list2
        } else if (val == 2) {
          this.list = this.list3
        } else if (val == 3) {
          this.list = this.list4
        } else if (val == 4) {
          this.list = this.list5
        } else if (val == 5) {
          this.list = this.list6
        }
      },

      // 跳转到 人口统计 图表详情
      goToPersonStateDetail() {
        let title = `巴林右旗${this.mapName}人口统计`

        this.$router.push({
          path: "/bigscreen/personState" + title
        })
      },

      // 跳转到 机械化统计 图表详情
      goToMashineStateDetail() {
        let title = `${this.mapName}机械化统计`

        this.$router.push({
          path: "/bigscreen/mashineState" + title
        })
      },

      
    }
  }
</script>

<style scoped lang="less">
  @import url('../../../../assets/css/bigscreen.less');
  .activeColor { color: #2366fc !important; }
</style>